import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import "../styles/bg.css";
import {AdIosaregionSwipetoslide} from "../components/react-slick/ad-iosaregion-swipetoslide";
import title from "../images/sale/xr-page1-title.png";
import banner from "../images/sale/xr-page1-banner.gif";
import btn1 from "../images/sale/xr-btn1.png";
import btn2 from "../images/sale/xr-btn2.png";
import {Link} from "gatsby";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import KaisakuApi from "../controllers/kaisaku-api";
import cookie from "react-cookies";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            maxCount: 99,
            saleCount: 0,
            mode:''
        };
        let { userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL, m1, m2, mode} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        this.state.maxCount = parseInt(m1) + 1;
        this.state.saleCount = parseInt(m1) - parseInt(m2);
        this.state.mode = mode;        
    }

    async componentDidMount(){
        // 是否有销售数据,有显示,无,跳回游戏
        const res = await KaisakuApi.getInfoForM1(this.state.accessToken);
        if (res.success === true){
           const item = res.item;
            this.setState({
                maxCount: item.maxCount + 1,
                saleCount: (item.maxCount - item.saleCount)
                }
            );
        }else{
            // 是否有发货信息
            AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
        }
    }

    async createTransactionForM1() {
        console.log("创建订单");

        const res = await KaisakuApi.createTransactionForM1(this.state.accessToken);
        if (res.status === 200){
            const data = res.data;
            navigate(`/xr-saleing-page3${this.props.location.search}&transactionId=${data.transactionId}&buyId=${data.buyId}`);
            this.setState({
                message:""
            })
        }else{
            this.setState({
                message:"購買失敗"
            })
            // alert("已售罄");
        }

    }

    render() {
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                        <div style={{
                            width: '289px',
                            borderRadius: '10px 10px 0px 0px',
                            display: ''
                        }}>

                            <div style={{width:'289px', borderRadius:'10px 10px 0px 0px', backgroundColor:"#262626",borderLeft:'1px solid #444',borderTop:'1px solid #444',borderRight:'1px solid #444'}}
                            >
                                <Image style={{}} centered src={title}/>
                                {(!this.state.mode) && (
                                    <div style={{position:"absolute",left:"10px",top:"10px", color:'#666'}}
                                onClick={() => {
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                }}>回到遊戲</div>
                                )
                                }

{(this.state.mode) && (
                                    <div style={{position:"absolute",left:"10px",top:"10px", color:'#666'}}
                                onClick={() => {
                                    navigate('/sdk-callback');                                    
                                }}>返回</div>
                                )
                                }
                                
                            </div>
                            <div style={{width:"100%", textAlign:"center", paddingTop:"0px",paddingBottom:"10px",fontSize:"1.5em", fontWeight:"bold", backgroundColor:"#262626", color:"#FFF"}}>限定大陸地區</div>
                            <div style={{width:'289px',minHeight:"100px", maxHeight:"400px",overflowY:"auto"}}
                                 onClick={() => {
                                     navigate(`/xr-saleing-page2${this.props.location.search}`);
                                 }}
                            >
                                <Image style={{}} centered src={banner}/>
                                
                            </div>
                            
                            <div style={{width:'289px',minHeight:"60px",paddingTop:"10px",maxHeight:"400px",backgroundColor:"#262626",overflowY:"auto"}}
                                 onClick={() => {
                                     navigate(`/xr-saleing-page2${this.props.location.search}`);
                                 }}
                            >
                                <Image style={{width:"90%"}} centered src={btn1}/>
                                
                            </div>
                            <div style={{width:'289px',minHeight:"50px", maxHeight:"400px", paddingTop:"0px",backgroundColor:"#262626", borderRadius:'0px 0px 0px 0px'}}    
                            onClick={async () => {
                                await this.createTransactionForM1();
                            }}                             
                            >
                                <Image style={{width:"92%"}} centered src={btn2}/>
                            </div>
                            
                            <div style={{height:'41px',textAlign:'center',fontSize:'14px',fontWeight:'bold',
                                paddingTop:'14px',border:'1px solid #D6D6D6',                                
                                borderRadius:'0px 0px 10px 10px',
                                opacity:'1'}}
                                 onClick={() => {
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     if (this.state.mode){
                                        navigate("/sdk-callback");
                                     }else{
                                       AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                     }
                                 }}
                            >
                                <span style={{color:'#FF7700'}}>知道了~</span>
                            </div>
                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});

